import React from 'react'
import Layout from '../components/layout/Layout'
import DiversifyTiles from '../components/home/DiversifyTiles'
import CurrentHighlights from '../components/home/CurrentHighlights'
import HeroImage from '../components/images/HeroImage'

const Demo = () => {
  return (
    <Layout>
      <div id="demo" className="page-content">
        <DiversifyTiles />

        <section id="one">
          <div className="inner">
            <h1>One</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Purus
              gravida quis blandit turpis cursus in hac. Ut porttitor leo a
              diam. Nulla facilisi etiam dignissim diam quis enim lobortis
              scelerisque. Amet purus gravida quis blandit turpis cursus in hac
              habitasse. Arcu bibendum at varius vel pharetra vel. Sit amet
              justo donec enim diam. Montes nascetur ridiculus mus mauris vitae
              ultricies leo. Enim nec dui nunc mattis enim ut. Magna ac placerat
              vestibulum lectus mauris ultrices. Imperdiet sed euismod nisi
              porta lorem.
            </p>
            <a href="/" className="button">
              One
            </a>
          </div>
        </section>

        <CurrentHighlights />

        <section id="two">
          <div className="inner">
            <h1>One</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Purus
              gravida quis blandit turpis cursus in hac. Ut porttitor leo a
              diam. Nulla facilisi etiam dignissim diam quis enim lobortis
              scelerisque. Amet purus gravida quis blandit turpis cursus in hac
              habitasse. Arcu bibendum at varius vel pharetra vel. Sit amet
              justo donec enim diam. Montes nascetur ridiculus mus mauris vitae
              ultricies leo. Enim nec dui nunc mattis enim ut. Magna ac placerat
              vestibulum lectus mauris ultrices. Imperdiet sed euismod nisi
              porta lorem.
            </p>
            <a href="/" className="button">
              Two
            </a>
          </div>
        </section>

        <HeroImage />
      </div>
    </Layout>
  )
}

export default Demo
