import React from 'react'
import { Link } from 'gatsby'
import picForest from '../../assets/images/diversify/forest800x600.png'
import picGoat from '../../assets/images/diversify/goatsPhotoCreditDaynPullen800x410.jpg'
import picBike from '../../assets/images/diversify/mtbike800x400.png'
import picCabin from '../../assets/images/diversify/cabin800x533.png'

const tileData = [
  {
    title: 'Forestry',
    subtitle: 'Landscape and Forestry',
    link: '/diversify/forestry',
    picture: picForest,
  },
  {
    title: 'Ranching',
    subtitle: '',
    link: '/diversify/ranching',
    picture: picGoat,
  },
  {
    title: 'Mountain Biking',
    subtitle: '',
    link: '/diversify/mountainbiking',
    picture: picBike,
  },
  {
    title: 'Hospitality',
    subtitle: '',
    link: '/diversify/hospitality',
    picture: picCabin,
  },
]

const Diversify = (props) => (
  <section id="diversify-tiles" className="tiles">
    {tileData.map((card, index) => (
      <article style={{ backgroundImage: `url(${card.picture})` }}>
        <Link
          aria-label="Link to Landing Page"
          to={card.link}
          className="link primary"
        >
          <header className="major">
            <h3>{card.title}</h3>
            <p>{card.subtitle}</p>
          </header>
        </Link>
      </article>
    ))}
  </section>
)

export default Diversify
